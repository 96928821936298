import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
// import SearchBar from "../components/search"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Formik, Form, Field } from "formik"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `Title`
  const index = data.localSearchPages.index
  const store = data.localSearchPages.store
//   const options = {
//     tokenize: "forward",
//     suggestions: true,
//   }
  const initalSearch = location.state ? location.state.searchQuery : null
  const [query, setQuery] = useState(initalSearch)
  const results = useFlexSearch(query, index, store)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      {/* <SearchBar /> */}
      <Formik
        initialValues={{ query: location.state ? location.state.searchQuery : '' }}
        onSubmit={(values, { setSubmitting }) => {
            setQuery(values.query)
            setSubmitting(false)
        }}
        >
        <Form>
          <Field name="query" />
        </Form>
      </Formik>
      <h1>Search Results:</h1>

      <ul>
        {results.map(result => (
          <li key={result.id}>
             <Link to={result.slug} style={{ color: "#39C9A7" }}>
              {result.title}
           </Link>
           <div dangerouslySetInnerHTML={{__html: result.excerpt}}/>
          
              </li>
        ))}
      </ul>
      {/* <HomeNav /> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchPages {
      index
      store
    }
  }
`
